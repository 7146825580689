import { useState, useEffect } from "react";

/**
 * Custom hook that returns the current window dimension based on the specified dimension type.
 * The dimension updates when the window is resized.
 *
 * @param {"height" | "width"} dimensionType - The type of dimension to track. Can be either "height" or "width".
 * @returns {number} The current dimension value (either height or width) of the window.
 *
 * @example
 * const width = useDimensions("width");
 * const height = useDimensions("height");
 */

export const useDimensions = (dimensionType) => {
  const [dimension, setDimension] = useState(
    dimensionType === "height" ? window.innerHeight : window.innerWidth
  );

  useEffect(() => {
    const updateWindowDimension = () => {
      setDimension(
        dimensionType === "height" ? window.innerHeight : window.innerWidth
      );
    };

    window.addEventListener("resize", updateWindowDimension);

    return () => {
      window.removeEventListener("resize", updateWindowDimension);
    };
  }, [dimensionType]);

  return dimension;
};
