import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { useDimensions } from "../hooks/useDimensions";

/**
 * DialActionWhats es un componente funcional que muestra un botón flotante con un icono de WhatsApp.
 * Al hacer clic en el botón, se abre un enlace de WhatsApp en una nueva pestaña o ventana.
 * @returns {JSX.Element} Componente del botón de acción de WhatsApp con tooltip.
 */
const DialActionWhats = () => {
  /**
   * Obtiene las dimensiones del componente.
   * @type {number}
   */
  const dimensionWidth = useDimensions("width");
  /**
   * Determina si el ancho de la pantalla corresponde a un dispositivo móvil.
   * @type {boolean}
   */
  const PhoneWidth = dimensionWidth < 1000;
  /**
   * StyledButton es un componente de botón estilizado que flota en la parte inferior derecha de la pantalla.
   * @param {Object} theme - El tema de Material-UI para aplicar estilos.
   * @returns {JSX.Element} Componente de botón estilizado.
   */
  const StyledButton = styled(Button)(({ theme }) => ({
    position: "fixed", // Cambiado a fixed para que flote en la pantalla
    bottom: theme.spacing(4),
    right: PhoneWidth ? theme.spacing(2) : theme.spacing(8),
    borderRadius: "50%",
    width: PhoneWidth ? 40 : 60,
    height: PhoneWidth ? 40 : 60,
    boxShadow: PhoneWidth ? theme.shadows[0] : theme.shadows[4],
  }));

  /**
   * StyledTooltip es un componente de tooltip estilizado que muestra un mensaje de ayuda personalizado.
   * @param {Object} props - Las propiedades del componente Tooltip.
   * @returns {JSX.Element} Componente de tooltip estilizado.
   */
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgba(239, 247, 234, 0.78)",
      color: "rgba(53, 108, 45, 1)",
      width: 250,
      height: 80,
      borderRadius: 30,
      justifyContent: "center",
      alignItems: "center",
    },
  }));
  /**
   * handleClick es una función que se ejecuta cuando se hace clic en el botón.
   * Abre un enlace de WhatsApp en una nueva pestaña o ventana del navegador.
   */
  const handleClick = () => {
    window.open("https://wa.link/dgvzl7", "_blank"); //¡Hola MoviCare! ¿Podrían ayudarme a cotizar un servicio con ustedes? Por favor ☺️ 🌐
  };

  return (
    <StyledTooltip
      title={
        <React.Fragment>
          <Typography variant="h6" gutterBottom sx={design.txt}>
            ¿Necesitas ayuda?
          </Typography>
          <Typography variant="body2" gutterBottom sx={design.txt}>
            Escríbenos y te ayudaremos
          </Typography>
        </React.Fragment>
      }
      placement="left"
      // arrow
      followCursor
    >
      <StyledButton onClick={handleClick}>
        <Box
          component="img"
          alt="WhatsApp_MoviCare"
          src="https://movicaremx.com/IMG/Sitioweb/Icono_whatsapp.png"
          width={PhoneWidth ? 40 : 60}
          height={PhoneWidth ? 40 : 60}
          //375x375
        />
      </StyledButton>
    </StyledTooltip>
  );
};

export default DialActionWhats;

/**
 * design es un objeto que contiene estilos personalizados para el texto dentro del tooltip.
 * @type {Object}
 */
const design = {
  txt: {
    fontFamily: "Lexend",
    color: "rgba(53, 108, 45, 1)",
    fontWeight: 500,
    textAlign: "center",
  },
};
