import { Button, styled } from "@mui/material";

/**
 * CssButtonContained component is a styled Material-UI Button with custom styles.
 * 
 * @param {object} props - Component props.
 * @param {string} [props.width="60%"] - Button width.
 * @param {string} [props.height="66px"] - Button height.
 * @param {string} [props.colorText] - Text color of the button.
 * @param {string} [props.backgroundColor] - Background color of the button.
 * @param {string} [props.boxshadowcolor] - Box shadow color of the button.
 * @param {string} [props.border] - Border color of the button on hover.
 * @param {string} [props.backgroundcolorhover] - Background color of the button on hover.
 * 
 * @returns {JSX.Element} Styled Material-UI Button component.
 */
export const CssButtonContained = styled(Button)(
  ({
    theme,
    colortext,
    width,
    height,
    backgroundcolor,
    boxshadowcolor,
    backgroundcolorhover,
    bordercolor,
    fontSize,
    colortexthover,
  }) => ({
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "15px 25px",
    gap: "10px",
    width: width || "60%",
    height: height ? height : "66px",
    margin: "5% 2%",
    textTransform: "none",
    fontWeight: 600,
    fontFamily:  [
      'Garabito',
      'sans-serif',
    ].join(','),
    fontSize: fontSize,
    backgroundColor: backgroundcolor,
    borderRadius: "15px",
    border: `5px solid ${bordercolor}`,
    color: colortext || theme.palette.primary,
    boxShadow: `10px 10px 37px 0px ${boxshadowcolor}`,
    "&:hover": {
      border: `5px solid ${bordercolor}`,
      backgroundColor: backgroundcolorhover,
    color: colortexthover || theme.palette.primary,
      fontFamily:  [
        'Garabito',
        'sans-serif',
      ].join(','),
      textTransform: "none",
      fontWeight: 500,
    },
  })
);
// backgroundColor,boxShadowColor
// DARKBLUE --> #00518C
// DARKGREEN --> #65B231 Y rgba(101, 179, 46, 0.25)
// DARKRED --> #770a0a Y rgba(119, 10, 10, 0.25)
// MODERATEBLUE --> #4496D2 Y rgba(0, 81, 140, 0.50)
// SOFTBLUE --> #90A4F3 Y #6784F6
// SOFTORANGE --> #F08050 Y #FF7539