import React from "react";
import ReactDOM from "react-dom";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";

/**
 * Inicializa Google Analytics para el seguimiento del sitio web.
 * @see {@link https://github.com/react-ga/react-ga4} Para más detalles sobre la configuración de Google Analytics con react-ga4.
 */
ReactGA.initialize("G-X6MZFH33CR");

/**
 * Función principal que renderiza la aplicación React dentro del elemento root del DOM.
 * Utiliza React.StrictMode para detectar problemas potenciales y mejora la experiencia de desarrollo.
 * @returns {void}
 */
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <SnackbarProvider dense maxSnack={2}>
        <App />
      </SnackbarProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

/**
 * Envía las métricas de rendimiento de la aplicación a Google Analytics.
 * @param {Object} param - Objeto que contiene las métricas de rendimiento.
 * @param {string} param.id - ID único para la carga actual de la página.
 * @param {string} param.name - Nombre de la métrica de rendimiento.
 * @param {number} param.value - Valor de la métrica de rendimiento.
 * @returns {void}
 */
function sendToAnalytics({ id, name, value }) {
  ReactGA('send', 'event', {
    eventCategory: 'Web Vitals',
    eventAction: name,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // los valores deben ser enteros
    eventLabel: id || 'ID desconocido', // ID único para la carga actual de la página
    nonInteraction: true, // evita afectar la tasa de rebote
  });
}

// Inicia la medición de las métricas de rendimiento de la aplicación y las envía a Google Analytics.
reportWebVitals(sendToAnalytics);
