import React, { useState } from "react";
import Skeleton from "@mui/material/Skeleton";

/**
 * A component that renders an image with lazy loading and displays a skeleton placeholder while the image is loading.
 * 
 * @component
 * @param {Object} props - The properties object.
 * @param {string|number} props.w - The width of the image.
 * @param {string|number} props.h - The height of the image.
 * @param {string} props.src - The source URL of the image.
 * @param {string} props.title - The title of the image, used for accessibility.
 * @param {string} props.alt - The alternative text for the image, used for accessibility.
 * @param {Object} [props.style] - Optional inline styles to apply to the image.
 * @param {Object} [props.variant] - Optional inline styles to apply to the skeleton variant.
 * 
 * @returns {JSX.Element} The rendered image component with lazy loading and a skeleton placeholder.
 */
const LazyImage = ({ w, h, src, title, alt, style,variant }) => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <Skeleton variant={variant} width={w} height={h} />}
      <img
        width={w}
        height={h}
        style={style}
        src={src}
        title={title}
        alt={alt}
        aria-label={title}
        loading="lazy"
        onLoad={() => setLoading(false)}
      />
    </>
  );
};

export default LazyImage;
